<template>
	<div class="leadProgram">
		<Loader v-if="!eventInfo || !eventInfo.id" />
		<div class="leadProgram__col1" style="width: calc(100% - 10vw); min-height: 100vh;" v-if="eventInfo && submitted">
			<div style="max-width: 640px; margin:auto;">
			<div class="shiftCard mb-5">
				<h3>Thank you!</h3>
				<p>We will be in touch.</p>
				
			</div>
			<div class="shiftCard mb-5">
				<div class="nav__logo">
          <span>J<em>u</em>mp</span>
        </div>
        <p>Create a password to create your account and complete your application. You will also get access to all of our other gig and career opportunities:</p>
				<div class="mb-3">
          <label for="password2">Create a Password</label>
          <input v-model.trim="form.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6" />
        </div>
        <button class="btn btn__dark btn__xlarge mt-3" :disabled="!form.password" @click="signup()">
        Submit
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
			</div>
			</div>
		</div>
		<div class="leadProgram__col1" style="width: calc(100% - 10vw); min-height: 100vh;" v-if="shiftInfo && !submitted">
			<form @submit.prevent style="max-width: 640px; margin:auto;">
	  		<div class="shiftCard mb-5">
	  			<div class="nav__logo">
	          <span>J<em>u</em>mp</span>
	        </div>
	  			<h1>{{shiftInfo.title || shiftInfo.event}}</h1>
	  			<div v-if="shiftInfo && shiftInfo.position" class="mt-2">
	  				<button class="chip mr-3 mb-4 mt-2">{{shiftInfo.position.title}} | ${{shiftInfo.position.rate}}/hr</button>
	  			</div>

	  			 <div class="mb-3" v-if="shiftInfo && shiftInfo.position && shiftInfo.position.description">
	  			 	<div class="strong mb-1">Job Description</div>
			  			 
			  			<span class="caption">{{shiftInfo.position.description}}</span>
			  		</div>
    <div class="caption mb-3" v-if="shiftInfo && shiftInfo.details">{{shiftInfo.details}}</div>
	  			<!-- <div v-if="eventInfo.jobs && eventInfo.jobs.length >= 1" class="mt-2">
	  				<button class="chip mr-3 mb-3" v-for="(item, index) in eventInfo.jobs" :key="index">{{item.title}} | ${{item.rate}}/hr</button>
	  			</div> -->
	  			<div v-if="shiftInfo && shiftInfo.day && shiftInfo.startTime && shiftInfo.endTime" class="caption mb-2">
	  				<span>{{shiftInfo.day | moment("dddd, MMMM Do YYYY") }} | {{formatAMPM(shiftInfo.startTime) | moment("h:mm:ss a") }} to {{formatAMPM(shiftInfo.endTime) | moment("h:mm:ss a")  }}</span>
	  			</div>

	  			<hr v-if="isPermanent">
	  			

	  			<div v-if="isPermanent" class="mb-3 mt-3">
			      <div class="strong mb-1">Shift Schedule</div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.sundayStart && shiftInfo.sundayEnd">
			        Sunday: {{formatAMPM(shiftInfo.sundayStart)}} - {{formatAMPM(shiftInfo.sundayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.mondayStart && shiftInfo.mondayEnd">
			        Monday: {{formatAMPM(shiftInfo.mondayStart)}} - {{formatAMPM(shiftInfo.mondayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.tuesdayStart && shiftInfo.tuesdayEnd">
			        Tuesday: {{formatAMPM(shiftInfo.tuesdayStart)}} - {{formatAMPM(shiftInfo.tuesdayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.wednesdayStart && shiftInfo.wednesdayEnd">
			        Wednesday: {{formatAMPM(shiftInfo.wednesdayStart)}} - {{formatAMPM(shiftInfo.wednesdayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.thursdayStart && shiftInfo.thursdayEnd">
			        Thursday: {{formatAMPM(shiftInfo.thursdayStart)}} - {{formatAMPM(shiftInfo.thursdayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.fridayStart && shiftInfo.fridayEnd">
			        Friday: {{formatAMPM(shiftInfo.fridayStart)}} - {{formatAMPM(shiftInfo.fridayEnd) | moment("h:mm:ss a") }}
			      </div>
			      <div class="caption mb-1" v-if="shiftInfo && shiftInfo.saturdayStart && shiftInfo.saturdayEnd">
			        Saturday: {{formatAMPM(shiftInfo.saturdayStart)}} - {{formatAMPM(shiftInfo.saturdayEnd) | moment("h:mm:ss a") }}
			      </div>

			    </div>
			    <hr>

			    <p v-if="shiftInfo && shiftInfo.location" class="mt-3">We are actively hiring in {{shiftInfo.location}}. Please complete the interest form below and we will be in touch right away.</p>

	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="firstname">First Name:</label>
          <input v-model.trim="form.first" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
          
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="lastname">Last Name:</label>
          <input v-model.trim="form.last" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="email">Email:</label>
          <input v-model.trim="form.email" autocomplete="email" type="text" placeholder="" id="email" required />
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="phone">Cell Phone</label>
           <input v-model.trim="form.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
	  		</div>

	  		<div class="shiftCard mb-3">
              <label for="dob">Date of Birth:</label>
              <div style="width:100%;">
                <input type="date" placeholder="" v-model.trim="form.dob" id="dob" required/>
              </div>
            </div>
	  		<div class="shiftCard mb-5">
	  			<label for="hours">How many hours are you looking to work each week?</label>
	        <v-select
	          label="title" 
	          :options="hours"
	          multiple
	          v-model="form.hours"
	          >
	        </v-select>
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="legal">Are you legally allowed to work in the United States?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.legal"
	          >
	        </v-select>
	  		</div>

	  		<div class="shiftCard mb-5" v-if="eventInfo && eventInfo.subClient && eventInfo.subClient[0] && eventInfo.subClient[0].title && eventInfo.subClient[0].title == 'Amazon'">
          <label for="legal">Have you ever worked at an Amazon location before?</label>
           <v-select
            label="title" 
            :options="yesNo"
            v-model="form.amazon"
            >
          </v-select>
        </div>



	  		<div class="shiftCard mb-5">
        <label for="standing">Are you comfortable standing and walking for long periods of time?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.standing"
	          >
	        </v-select>
	      </div>
	  		<div class="shiftCard mb-5">
	  			<label for="forty">Are you able to lift and move a box that weighs 40lbs?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.forty"
	          >
	        </v-select>
	  		</div>
	  		<div class="shiftCard mb-5">
	        <label for="background">Is there anything we should be aware of when we run your background check?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.background"
	          >
	        </v-select>
	        <transition name="fade">
	          <div class="mt-3" v-if="form.background == 'Yes'">
	            <label for="explain">Please explain:</label>
	            <input type="text" placeholder="" v-model.trim="form.explain" id="explain" />
	          </div>
	        </transition>
	      </div>
	      <div class="shiftCard mb-5">
	        <label for="passDrugTest">Are you confident that you would pass a drug test?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.passDrugTest"
	          >
	        </v-select>
	      </div>
	      <div class="shiftCard mb-5">
	        <label for="transportation">Do you have reliable transportation?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.transportation"
	          >
	        </v-select>
	      </div>
	      <div class="shiftCard mb-5">
	        <label for="nightsWeekends">Are you able to work nights or weekends?</label>
	        <v-select
	          label="title" 
	          :options="yesNoSometimes"
	          v-model="form.nightsWeekends"
	          >
	        </v-select>
	      </div>
	  		<div class="shiftCard mb-5">
	  			<label for="experience">Tell us about your experience or anything else we should know about you:</label>
        	<textarea name="experience" id="" cols="30" rows="6" v-model.trim="form.experience"></textarea>
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="location">Your Address (or at least city and state)</label>
          <label>
            <gmap-autocomplete
              class="v-input v-input__control outline-fix"
              @place_changed="setPlace" placeholder="Enter Your Location">
            </gmap-autocomplete>
          </label>
        </div>
	  		<div class="shiftCard mb-5">
          <label for="password2">Create a Password</label>
          <input v-model.trim="form.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6" />
        </div>
	  		<button class="btn btn__dark btn__xlarge mt-3" :disabled="!valid" @click="signup()">
          Submit
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
	  		
  		</form>

  	</div>
	</div>
</template>

<style scoped>
	.google-form {

	}
	.leadProgram__col1 {
    background: #00c87f;
    width: 100%;
    padding: 6rem 5vw 6rem 5vw;
    color: #333 !important;
}
.shiftCard {
	background: white; padding: 2rem;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'googleForm',
  data: () => ({
    performingRequest: false,
    hours: ['0-10', '10-20', '20-30', '30-40'],
    yesNo: ['Yes', 'No'],
    yesNoSometimes: ['Yes', 'No', 'Sometimes'],
    form: {},
    submitted: false,
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
  }),
  created () {
    this.$store.dispatch("getShiftFromId", this.$route.params.id)
  },
  computed: {
    ...mapState(['eventInfo', 'currentUser', 'shiftInfo']),
    valid() {
    	if (this.form.first && this.form.last && this.form.email && this.form.phone && this.form.hours && this.form.legal && this.form.standing && this.form.forty && this.form.background && this.form.passDrugTest && this.form.transportation && this.form.nightsWeekends && this.form.password && this.form.password.length >= 6 && this.address && this.address.city) {
    		return true
    	} else {
    		return false
    	}
    },
    isPermanent() {
      return this.shiftInfo && this.shiftInfo.type && (this.shiftInfo.type == 'Permanent' || this.shiftInfo.type == 'Job')
    },
  },
  components: {
  	Loader
  },
  methods: {
  	setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          this.performingRequest = true
          this.address = {}
          setTimeout(() => {
              this.performingRequest = false
          }, 1000)
          console.log(this.address)
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature') {
              this.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.address.zip = component['short_name'];
          }
        }
      }
      this.place = ''
      this.$emit('clicked', this.valid)
    },
    // submitForm() {
    //   this.performingRequest = true
    //   let form = this.form
    //   form.market = {
    //   	city: this.eventInfo.event_city,
    //   	state: this.eventInfo.event_state
    //   }
    //   form.event = this.eventInfo
    //   form.source = 'our website'
    //   form.status = 'New'
    //   form.shift = this.shiftInfo
    //   form.day = this.shiftInfo.day
    //   form.event = this.eventInfo
    //   console.log(form)
    //   let store = this.$store
    //   store.dispatch('newLeadSubmission', form)
    //   setTimeout(() => {
    //     this.performingRequest = false
    //     this.submitted = true
    //   }, 2000)
    // },
    async signup() {
    	this.performingRequest = true
    	await this.$store.dispatch('signupForm', {
        email: this.form.email,
        password: this.form.password,
        firstName: this.form.first,
        phone: this.form.phone,
        lastName: this.form.last,
        dob: this.form.dob,
        interview: this.form,
        shift: this.shiftInfo,
	      day: this.shiftInfo.day,
	      event: this.eventInfo,
	      address: this.address,
	      center: this.center,
      })


      await this.$store.dispatch('addJobAvailabilityFromSignup', {
        event: this.eventInfo,
        shift: this.shiftInfo,
        type: this.shiftInfo.type,
        user: this.form
      });

    	this.form = {}
  		this.address = {}
  		this.performingRequest = false
    },
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    this.$store.dispatch("clearEventState")
    this.form = {}
    delete this.form
    delete this.performingRequest
  },
}
	
</script>